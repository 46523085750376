<template>
	<div>
		<div v-if="isMounted" class="panel m-5">
			<PanelHeader panel-header="Stammdaten" />
			<div class="container pt-3 pb-3">
				<div class="columns is-centered">
					<b-field label="Profilbild" class="column image_column">
						<ImageWithDelete v-if="dropFile" :delete-drop-file="deleteDropFile"
							:drop-file-u-r-l="getPicture" />
						<ImageUpload v-else :drop-file="dropFile" :set-drop-file="(file) => (dropFile = file)" />
					</b-field>
				</div>
				<hr>
				<div class="columns">
					<InputWithValidation class="column" message="Bitte gib einen Vornamen ein!" :name="firstName"
						:set-name="(name) => (firstName = name)" label="Vorname" />
					<InputWithValidation class="column" message="Bitte gib einen Nachnamen ein!" :name="lastName"
						:set-name="(name) => (lastName = name)" label="Nachname" />
				</div>
				<div class="columns">
					<SelectionComponent :key="gender" class="column" label="Geschlecht" :options="genderArray"
						:selected-option="gender" :select="(item) => (gender = item)" />
					<InputComponent :key="isMounted ? 'birthLocationU' : 'birthLocation'" class="column"
						:name="birthLocation" :set-name="(name) => (birthLocation = name)" label="Geburtsort" />
					<DatePicker class="column" label="Geburtstag" :selected-date="birthday"
						:select="(date) => (birthday = date)" />
				</div>
			</div>
		</div>
		<!-- Bereich Fallstammdaten -->
		<div v-if="isMounted" class="panel m-5">
			<PanelHeader panel-header="Fallstammdaten" />
			<div class="container pt-3 pb-3">
				<div class="columns">
					<DatePicker class="column" label="Zugang" :selected-date="entry"
						:select="(date) => (entry = date)" />
					<DatePicker class="column" label="Abgang" :selected-date="exit" :select="(date) => (exit = date)" />
				</div>
			</div>
			<PanelHeader panel-header="Falldaten" />
			<div v-for="(caseData, index) in caseDataArray" :key="caseData.id">
				<div v-if="!caseData.isDeleted" class="container pt-3 pb-3">
					<hr v-if="index > 0" style="height: 3px; background-color: grey">
					<div class="columns">
						<b-field v-if="caseDataArray.filter(caseElement => !caseElement.isDeleted).length > 1"
							label="Entfernen" class="column">
							<IconButton :trigger-function="() => removeCaseData(caseData)" icon="minus" class="p-2" />
						</b-field>
						<ValidatedSelection :key="caseData.group ? caseData.group.id : 'group'" class="column"
							label="Gruppe" message="Bitte wähle Sie eine Gruppe aus!" attributeName="group_name"
							:has-validation="true" :options="groupArray" :selected-option="caseData.group"
							:select="(item) => (caseData.group = item)" />
						<ValidatedSelection
							:key="caseData.administrativeDistrict ? caseData.administrativeDistrict.id : 'district'"
							class="column" label="Landkreis" attributeName="name" :options="districts"
							:selected-option="caseData.administrativeDistrict"
							:select="(item) => (caseData.administrativeDistrict = item)" />
						<ValidatedSelection
							:key="caseData.statutoryBasis ? caseData.statutoryBasis.id : 'statutoryBasis'"
							class="column" label="Gesetzliche Grundlage" attributeName="name"
							:options="statutoryBasisArray" :selected-option="caseData.statutoryBasis"
							:select="(item) => (caseData.statutoryBasis = item)" />
						<ValidatedSelection :key="caseData.licenseKind ? caseData.licenseKind.id : 'licenseKind'"
							class="column" label="Bewilligungsart" attributeName="name" :options="licenseKind"
							:selected-option="caseData.licenseKind" :select="(item) => (caseData.licenseKind = item)" />
						<InputWithValidation class="column"
							message="Bitte tragen Sie einen validen Geldbetrag ein! Dieser hat mindestens eine Stelle vor dem Komma und genau 2 Nachkommastellen."
							:name="caseData.licenseFee" :set-name="(value) => (caseData.licenseFee = value)"
							label="Entgelt (in €)" type="currency" />
					</div>
					<div class="columns">
						<ValidatedDatePicker class="column" label="Bewilligung von"
							:selected-date="caseData.licenseStart" :select="(date) => (caseData.licenseStart = date)"
							message="Bitte wählen Sie ein Startdatum aus!" :has-validation="false" />
						<ValidatedDatePicker class="column" label="Bewilligung bis" :selected-date="caseData.licenseEnd"
							:select="(date) => (caseData.licenseEnd = date)"
							message="Bitte wählen Sie ein Enddatum aus!" :has-validation="false" />
						<SwitcherComponent v-if="client" :key="caseData.inactive ? 'active' : 'inactive'" class="column"
							label="Aktivität" :text="caseData.inactive ? 'inaktiv' : 'aktiv'"
							:is-activated="caseData.inactive"
							:switch-activation="() => (caseData.inactive = !caseData.inactive)" />
					</div>
					<hr>
					<div class="p-2">
						<div class="column anwesenheitstage"
							style="display: flex; justify-content: center; align-items: center">
							<div style="font-size: 1rem; font-weight: 700; margin-bottom: 0.375rem;">
								<p>Bewilligungsdatei</p>
							</div>
							<FileDelete v-if="caseData?.caseDataFile?.pdf !== undefined" class="column m-2"
								:text="caseData?.caseDataFile?.pdf?.attributes?.pdfTitle ? caseData?.caseDataFile?.pdf?.attributes.pdfTitle : caseData?.caseDataFileObjekt?.attributes.pdfTitle"
								:trigger-delete="() => caseData.caseDataFile.pdf = undefined" />
							<SingleUpload v-else class="column m-2" :data="caseData?.caseDataFile?.pdf"
								:object="caseData.caseDataFile" :is-loading="isLoading" accept=".pdf"
								:set-loading="(value) => isLoading = value" :set-pdf="addPdf" label="PDF"
								message="Bitte laden Sie eine PDF-Datei hoch!" :has-validation="false" />
						</div>
						<div v-if="caseData?.caseDataFile?.pdf" class="columns">
							<InputWithValidation class="column" label="Bezeichner" :name="caseData.caseDataFile.title"
								:set-name="(val) => caseData.caseDataFile.title = val"
								message="Btte geben Sie einen Dokumentenbezeichner ein!" />
							<ValidatedSelection class="column" label="Kategorie" :options="categories"
								:selected-option="caseData?.caseDataFile?.category"
								:select="data => caseData.caseDataFile.category = data"
								message="Bitte fügen Sie eine Dokumentenkategorie hinzu!" :has-validation="true"
								attribute-name="name" />
							<SelectionComponent class="column" label="Posteingang/Postausgang"
								:options="['Eingang', 'Ausgang', 'Intern', 'Extern', 'Posteingang', 'Postausgang']"
								:selected-option="caseData.caseDataFile.documentType"
								:select="data => caseData.caseDataFile.documentType = data" />
						</div>
						<div v-if="caseData?.caseDataFile?.pdf" class="columns p-2">
							<ValidatedDatePicker :key="caseData?.caseDataFile?.date?.toLocaleDateString()"
								class="column" label="Datum" :selected-date="caseData.caseDataFile.date"
								:select="(date) => (caseData.caseDataFile.date = date)"
								message="Bitte wählen Sie das Empfangsdatum aus!" :has-validation="true" />
							<InputWithValidation class="column" label="Ersteller"
								message="Bitte fügen Sie einen Ersteller hinzu!"
								:set-name="(val) => caseData.caseDataFile.creator = val" type="text"
								:name="caseData.caseDataFile.creator" />
						</div>
					</div>
				</div>
			</div>
			<hr class="mx-3" style="height: 3px; background-color: grey">
			<div style="cursor: pointer;display: flex;justify-content: center; align-items: center" class="pb-4">
				<b-button style="cursor: pointer" class="is-small mr-2" @click="addCaseData">
					Neuen Bewilligungszeitraum hinzufügen
				</b-button>
			</div>
		</div>
		<!-- Bereich Kontakt- und Adressdaten -->
		<div v-if="isMounted" class="panel m-5">
			<PanelHeader panel-header="Kontakt- und Adressdaten" />
			<div class="container pt-3 pb-3">
				<div class="columns">
					<InputComponent :key="isMounted ? 'phone1u' : 'phone1'" class="column" :name="phone1"
						:set-name="(val) => (phone1 = val)" label="Telefon 1" />
					<InputComponent :key="isMounted ? 'info1u' : 'info1'" class="column" :name="info1"
						:set-name="(val) => (info1 = val)" label="Info 1" />
				</div>
				<div class="columns">
					<InputComponent :key="isMounted ? 'phone2u' : 'phone2'" class="column" :name="phone2"
						:set-name="(val) => (phone2 = val)" label="Telefon 2" />
					<InputComponent :key="isMounted ? 'info2u' : 'info2'" class="column" :name="info2"
						:set-name="(val) => (info2 = val)" label="Info 2" />
				</div>
				<div class="columns">
					<InputComponent :key="isMounted ? 'streetu' : 'street'" class="column" :name="street"
						:set-name="(name) => (street = name)" label="Straße" />
					<InputComponent :key="isMounted ? 'plzu' : 'plz'" class="column" :name="plz"
						:set-name="(name) => (plz = name)" label="PLZ" />
					<InputComponent :key="isMounted ? 'locationu' : 'location'" class="column" :name="location"
						:set-name="(name) => (location = name)" label="Ort" />
				</div>
				<div class="columns">
					<ValidatedSelection :key="school ? school.id : 'school'" class="column" label="Schule/Kindergarten"
						attributeName="name" :options="schools" :selected-option="school"
						:select="(item) => (school = item)" />
				</div>
			</div>
		</div>
		<!-- Bereich Personen -->
		<div v-if="isMounted" class="panel m-5">
			<PanelHeader panel-header="Personen" />
			<div class="container pt-3 pb-3">
				<ValidatedSelection :key="socialWorker ? socialWorker.id : 'group'" class="column"
					label="Sozialarbeiter" attributeName="first_name" second-attribute-name="last_name"
					:options="socialWorkers" :selected-option="socialWorker"
					:select="(item) => (socialWorker = item)" />

				<div v-for="(person, index) in chosenPersons" :key="person.id" class="columns">
					<div style="display: flex; align-items: center; justify-content: center"
						class="column m-2 is-one-fifth">
						<b-field label="Entfernen">
							<IconButton icon="minus" :trigger-function="() => removePerson(person.id)" />
						</b-field>
					</div>

					<b-field class="column m-2 is-5">
						<template #label>
							<div class="align-content-center" style="display: inline-flex">
								<p>Person {{ index + 1 }}</p>
							</div>
						</template>
						<b-select :key="isMounted ? 'personM' : 'personU'" v-model="person.person" class="m-2" expanded
							@input="e => setPerson(e, person, index)">
							<option v-for="(option) in persons" :key="option.id" :value="option.toPointer()">
								{{ option.attributes.first_name }} {{ option.attributes.last_name }}, {{
			option.attributes.type }}
							</option>
						</b-select>
					</b-field>
					<div class="column">
						<b-field label="Sorgerecht" class="m-2 is-one-fifth">
							<b-switch v-model="person.hasCustody" @input="e => setCustody(e, person, index)">
								<div v-if="person.hasCustody">
									Ja
								</div>
								<div v-if="!person.hasCustody">
									Nein
								</div>
							</b-switch>
						</b-field>
					</div>
				</div>
				<IconButton icon="plus" :trigger-function="addPerson" />
			</div>
		</div>

		<div v-if="isMounted" class="panel m-5">
			<PanelHeader panel-header="Bewertungsstichtag" />
			<div class="container pt-3 pb-3">
				<b-select v-model="goalDay">
					<option :value="1">
						Montag
					</option>
					<option :value="2">
						Dienstag
					</option>
					<option :value="3">
						Mittwoch
					</option>
					<option :value="4">
						Donnerstag
					</option>
					<option :value="5">
						Freitag
					</option>
					<option :value="6">
						Samstag
					</option>
					<option :value="0">
						Sonntag
					</option>
				</b-select>
			</div>
		</div>

		<div v-if="isMounted" class="m-5 buttons" style="display: flex; justify-content: center">
			<SaveButton :is-loading="isLoading" :disabled="$v.firstName.$invalid ||
			$v.lastName.$invalid || $v.caseDataArray.$invalid" :save-function="checkforDuplicate" />
			<CancelButton text="Abbrechen" back-route="/clients" :is-loading="false" />
		</div>
		<duplicate-modal v-model="isDuplicateModalActive" @save-duplicated-client="editClient"/>
	</div>
</template>

<script>

/*
<hr>
				<div class="columns">
					<div class="column anwesenheitstage"
						style="display: flex; justify-content: center; align-items: center">
						<div style="font-size: 1rem; font-weight: 700; margin-bottom: 0.375rem;">
							<p>Anwesenheitstage</p>
						</div>
						<div class="column anwesenheitstage"
							style="display: flex; justify-content: center; align-items: start">
							<b-checkbox style="margin-bottom: 0.25em;" v-model="caseData.licenseDays" :native-value=1>
								Montag
							</b-checkbox>
							<b-checkbox style="margin-bottom: 0.25em;" v-model="caseData.licenseDays" :native-value=2>
								Dienstag
							</b-checkbox>
							<b-checkbox style="margin-bottom: 0.25em;" v-model="caseData.licenseDays" :native-value=3>
								Mittwoch
							</b-checkbox>
							<b-checkbox style="margin-bottom: 0.25em;" v-model="caseData.licenseDays" :native-value=4>
								Donnerstag
							</b-checkbox>
							<b-checkbox style="margin-bottom: 0.25em;" v-model="caseData.licenseDays" :native-value=5>
								Freitag
							</b-checkbox>
							<b-checkbox style="margin-bottom: 0.25em;" v-model="caseData.licenseDays" :native-value=6>
								Samstag
							</b-checkbox>
							<b-checkbox style="margin-bottom: 0.25em;" v-model="caseData.licenseDays" :native-value=0>
								Sonntag
							</b-checkbox>
						</div>
					</div>
				</div>
*/


/**
 * This file is for creating and editing clients
 */
import Parse from "parse/dist/parse.min.js";
import {
	queryActiveAndInactiveClients,
	queryAttributeConfigs,
	queryEntryAttributes,
	queryGroups, queryLocationByType, queryPersonByType, queryPersonsWithOutSocialWorkers, queryStatutoryBasis,
} from "../../lib/Query";
import { minLength, required, requiredIf } from "vuelidate/lib/validators";
import { v4 as uuid } from "uuid";
import {
	GENDERS,
} from "../../lib/constants/Constants";
import { getClientPicture, resizeImage } from "@/lib/Picture";
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import PanelHeader from "@/components/helperComponents/PanelHeader";
import ImageUpload from "@/components/helperComponents/ImageUpload";
import ImageWithDelete from "@/components/helperComponents/ImageWithDelete";
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
import InputComponent from "@/components/helperComponents/InputComponent";
import SelectionComponent from "@/components/helperComponents/SelectionComponent";
import DatePicker from "@/components/helperComponents/DatePicker";
import ValidatedSelection from "@/components/helperComponents/ValidatedSelection";
import CancelButton from "@/components/helperComponents/CancelButton";
import SaveButton from "@/components/helperComponents/SaveButton";
import IconButton from "@/components/helperComponents/IconButton";
import ValidatedDatePicker from "@/components/helperComponents/ValidatedDatePicker";
import { checkContactEducator } from "@/lib/ContactClient";
import SwitcherComponent from "@/components/helperComponents/SwitcherComponent";
import { mapGetters } from "vuex";
import DuplicateModal from "@/components/modals/DuplicateModal.vue";

import { queryClientRecordCategories, queryClients } from "@/lib/Query";
import SingleUpload from "@/components/clientRecords/recordHelperComponents/SingleUpload";
import FileDelete from "@/components/clientRecords/recordHelperComponents/FileDelete";

async function getModifiedImage(value) {
	return await resizeImage(value);
}

const savePictures = async (client, dropFile, dropFileType) => {
	if (dropFile !== undefined && dropFileType !== "string") {
		const imageFile = new Parse.File(uuid(), { base64: dropFile }, "image/jpeg");
		client.set("profile_picture", imageFile);
		await imageFile.save(null, { useMasterKey: true });
	}
	if (dropFile === undefined && dropFileType === "undefined") client.unset("profile_picture")
	await client.save()
}
export default {
	name: "NewClient",
	components: {
		SwitcherComponent,
		IconButton,
		SaveButton,
		CancelButton,
		ValidatedSelection,
		DatePicker,
		InputWithValidation,
		ImageWithDelete,
		ImageUpload,
		PanelHeader,
		InputComponent,
		SelectionComponent,
		ValidatedDatePicker,
		SingleUpload,
		FileDelete,
		DuplicateModal,
	},
	data() {
		return {
			isDuplicateModalActive: false,
			//document-part
			categories: [],
			filter: "",
			//----
			checkboxGroup: [],
			firstName: "",
			lastName: "",
			birthday: undefined,
			gender: "",
			genderArray: GENDERS,
			isLoading: false,
			groupArray: [],
			phone1: "",
			phone2: "",
			plz: undefined,
			location: undefined,
			street: undefined,
			dropFile: undefined, //required as value before storing in vuex
			dropFileModified: undefined,
			groupData: [],
			entryAttributes: [],
			defEntryAttributes: [],
			entryAttributeConfigs: [],
			// districts: DISTRICTS,
			statutoryBasisArray: [], //STATUTORY_BASIS_ARRAY
			goalDay: 0,
			//for edit
			client: undefined,
			isMounted: false,
			focusedClients: [],
			//removedCaseDataIds: [],
			profile_picture: undefined,
			exit: undefined,
			entry: new Date(),
			caseDataArray: [
				{
					licenseDays: [],
					licenseKind: undefined,
					id: uuid(),
					group: undefined,
					administrativeDistrict: "", //Landkreis
					statutoryBasis: "", //gesetzliche Grundlage
					licenseStart: undefined,
					licenseEnd: undefined,
					licenseFee: "0",
					inactive: false,
					all_inactive: false,
					caseDataFileObjekt: undefined,
					caseDataFile: {
						title: undefined,
						category: undefined,
						documentType: "Posteingang",
						date: undefined,
						creator: "",
						pdf: undefined
					},
					isDeleted: false,
				},
			],
			birthLocation: "",
			info1: "",
			info2: "",
			district: "",
			districts: [],
			school: undefined,
			schools: [],
			socialWorker: undefined,
			socialWorkers: [],
			chosenPersons: [],
			persons: []
		};
	},
	computed: {
		getPicture() {
			if (!this.client && !(typeof this.dropFile === "string")) {
				return URL.createObjectURL(this.dropFile);
			}
			if (!this.client) return undefined;
			const pictureObject = {
				newProfilePicture:
					typeof this.dropFile === "string"
						? undefined
						: this.dropFile,
				clientPicture:
					typeof this.dropFile === "string"
						? this.dropFile
						: undefined,
				clientGender: this.client.attributes.gender,
			};
			return getClientPicture(pictureObject);
		},
		...mapGetters({
			staffUsersMeta: "getStaffUserMetas",
			licenseKind: "licenseKind/getData",
		})
	},
	watch: {
		dropFile: {
			handler: function (fileArray) {
				if (fileArray && fileArray.type) {
					this.dropFileModified = undefined;
					if (fileArray.type.match(/image.*/)) {
						getModifiedImage(fileArray)
							.then((response) => {
								this.dropFileModified = response;
							})
							.catch(function (error) {
								console.log(error);
							});
					}
				}
			},
			deep: true,
		}
	},
	async mounted() {
		this.clients = await queryClients()
		this.categories = await queryClientRecordCategories()
		this.groupArray = await queryGroups();
		this.entryAttributes = await queryEntryAttributes();
		this.defEntryAttributes = this.entryAttributes;
		this.entryAttributeConfigs = await queryAttributeConfigs();
		this.persons = await queryPersonsWithOutSocialWorkers()
		this.socialWorkers = await queryPersonByType("socialWorker")
		this.districts = await queryLocationByType("district")
		this.schools = await queryLocationByType("school")
		this.statutoryBasisArray = await queryStatutoryBasis()
		await this.setExistentClient();
	},
	validations: {
		firstName: {
			required,
			minLength: minLength(1),
		},
		lastName: {
			required,
			minLength: minLength(1),
		},
		caseDataArray: {
			required: true,
			$each: {
				group: {
					required,
				},
				licenseFee: {
					required,
				},
				caseDataFile: {
					title: {
						required: requiredIf(function (caseDataFile) {
							return caseDataFile.pdf
						}),
					},
					documentType: {
						required: requiredIf(function (caseDataFile) {
							return caseDataFile.pdf
						}),
					},
					date: {
						required: requiredIf(function (caseDataFile) {
							return caseDataFile.pdf
						}),
					},
					creator: {
						required: requiredIf(function (caseDataFile) {
							return caseDataFile.pdf
						}),
					},
					category: {
						category: requiredIf(function (caseDataFile) {
							return caseDataFile.pdf
						}),
					}
				}
			}
		},
	},
	methods: {
		async checkforDuplicate() {
			if (!this.$route.params.data) {
				let result = undefined
				const Client = Parse.Object.extend("Client");
				const query = new Parse.Query(Client);
				query.notEqualTo("deleted", true)
				query.notEqualTo("inactive", true)
				query.notEqualTo("all_inactive", true)
				query.equalTo("first_name", this.firstName)
				query.equalTo("last_name", this.lastName)
				if(this.birthday){
					query.greaterThanOrEqualTo("birth_date", new Date((this.birthday.setHours(0,0,0,0))))
					query.lessThan("birth_date", new Date((this.birthday.setHours(23,59,59,999))))
				}
				try {
					result = await query.find()
				} catch (e) {
					console.error(e)
				}
				if(result.length >= 1){
					this.isDuplicateModalActive = true
				} else {
					this.editClient()
				}
			} else {
				this.editClient()
			}
		},
		async editClient() {
			this.isLoading = true;
			const clientUuid = uuid(); // globale Client-ID, die immer neu erstellt wird, aber clienten-übergreifend

			for (const caseData of this.caseDataArray) {
				if (caseData.isDeleted) {
					// Löschen der caseData durch "löschen" des korrespondierenden Klienten
					const clientToBeDeleted = this.focusedClients.find((client) => (client.attributes.caseDataUuid == caseData.id))
					clientToBeDeleted.set("uuid", clientUuid); // die ändern wir mit, damit wir den Zusammenhang nicht verlieren
					clientToBeDeleted.set("deleted", true);
					await clientToBeDeleted.save();

					// TODO: Was muss hier noch passieren??
					continue
				}

				this.client = this.focusedClients.find((client) => client.attributes.caseDataUuid === caseData.id);

				// Standard-Fall (Hinzufügen/Editieren von CaseData)
				let caseDataFile = undefined
				let savedFile = undefined
				if (caseData.caseDataFile.pdf) {
					if (!caseData.caseDataFile.pdf._url) {
						const pdfFile = caseData.caseDataFile.pdf
						caseData["caseDataFileObjekt"] = pdfFile
						await pdfFile.save()
					}
					// Das PDF für den Fall mit den ausstehenden Attributen anreichern und dann speichern.
					caseDataFile = caseData.caseDataFileObjekt

					caseDataFile.set("creator", caseData.caseDataFile.creator)
					caseDataFile.set("title", caseData.caseDataFile.title)
					caseDataFile.set("documentType", caseData.caseDataFile.documentType)
					caseDataFile.set("date", caseData.caseDataFile.date)
					caseDataFile.set("category", caseData.caseDataFile.category)
					caseDataFile.set("readers", [])
					caseDataFile.set("uuid", uuid())

					savedFile = await caseDataFile.save()

					const params = {
						id: savedFile.id
					}
					await Parse.Cloud.run("readPdf", params)
				}

				const filteredPerson = this.chosenPersons.filter(p => p.person !== undefined)
				const personIds = filteredPerson.map(p => p.person.objectId)
				const custodyArray = filteredPerson.map(p => p.hasCustody)

				caseData.licenseDays.sort();

				const clientParams = {
					clientId: this.client ? this.client.id : undefined,
					firstName: this.firstName,
					lastName: this.lastName,
					birthday: this.birthday,
					groupId: caseData.group.objectId,
					gender: this.gender,
					administrativeDistrictId: caseData.administrativeDistrict.objectId,
					entry: this.entry,
					licenseKind: caseData.licenseKind ? caseData.licenseKind : undefined,
					licenseStart: caseData.licenseStart,
					licenseEnd: caseData.licenseEnd,
					licenseDays: caseData.licenseDays,
					licenseFee: Number((caseData.licenseFee).replace(",", ".")),
					licenseFile: savedFile ? savedFile.toPointer() : undefined,
					exit: this.exit,
					phone1: this.phone1,
					phone2: this.phone2,
					plz: this.plz,
					location: this.location,
					street: this.street,
					uuid: clientUuid,
					pictureId: uuid(),
					inactive: caseData.inactive,
					all_inactive: false,
					caseDataUuid: caseData.id,
					goalDay: this.goalDay,
					info1: this.info1,
					info2: this.info2,
					birth_location: this.birthLocation,
					schoolId: this.school ? this.school.objectId : undefined,
					socialWorkerId: this.socialWorker ? this.socialWorker.objectId : undefined,
					statutoryBasisId: caseData.statutoryBasis ? caseData.statutoryBasis.objectId : undefined,
					personIds: personIds,
					custodyArray: custodyArray,
				};

				const savedClient = this.client
					? await Parse.Cloud.run("editClient", clientParams)
					: await Parse.Cloud.run("createClient", clientParams);
				const dropFile = this.dropFile ? this.dropFileModified : undefined
				await savePictures(savedClient, dropFile, typeof this.dropFile)

				try {
					await savedClient.save();
				} catch (e) {
					console.error(e);
					negativeFeedBack("Fehler");
				}
				if (caseData.caseDataFile.pdf) {
					// Klienten-File abschließend noch einen Pointer für den Klienten anhängen.
					caseDataFile.set("client", savedClient)
					caseDataFile.save()
				}
				if (this.client) {
					//delete contact educator if group has changed
					await checkContactEducator(
						this.client,
						caseData.group,
						this.staffUsersMeta
					);
				}
				
				/*
				const queryNotifications = new Parse.Query("Notification");
				queryNotifications.equalTo("client", savedClient);

				
				const notifications = await queryNotifications.find();
				if (this.inactive) {
					//destroy all client notifications
					for (const notification of notifications) {
						notification.set("deleted", true);
						await notification.save();
					}
				}
				if (!this.inactive) {
					//restore all client notifications
					for (const notification of notifications) {
						notification.unset("deleted");
						await notification.save();
					}
				}*/
			} 

			// checken, ob wir den Klienten insgesamt deaktiven können
			const connectedClients = this.focusedClients.filter(c => c.attributes.uuid === clientUuid)
			const all_inactive_value = connectedClients.every(c => c.attributes.inactive)

			for (const client of connectedClients) {
				client.set("all_inactive", all_inactive_value);
				await client.save();
			}

			positiveFeedBack("Klient gespeichert!");
			await this.$router.push({ path: "/clients" });
			this.isLoading = false;
		},
		deleteDropFile() {
			this.dropFile = undefined;
			this.dropFileModified = undefined;
		},
		addCaseData() {
			this.caseDataArray.push({
				id: uuid(),
				group: undefined,
				administrativeDistrict: "",
				statutoryBasis: "",
				licenseFee: "0",
				licenseStart: undefined,
				licenseEnd: undefined,
				inactive: false,
				all_inactive: false,
				licenseKind: undefined,
				licenseDays: [],
				caseDataFile: {
					title: undefined,
					category: undefined,
					documentType: "Posteingang",
					date: undefined,
					creator: "",
					pdf: undefined
				},
				isDeleted: false
			});
		},
		removeCaseData(caseData) {
			// als gelöscht markieren
			const caseDataElement = this.caseDataArray.find(i => i.id == caseData.id);
			caseDataElement.isDeleted = true;
		},
		addPdf(pdf, object) {
			const pdfFile = new Parse.File(uuid(), pdf);
			const ClientFile = Parse.Object.extend("ClientFile")
			const newClientFile = new ClientFile()
			newClientFile.set("pdfTitle", pdf.name);
			newClientFile.set("pdf", pdfFile);
			newClientFile.set("pdfLastModifiedDate", pdf.lastModifiedDate);
			newClientFile.set("pdfSize", pdf.size);
			object.pdf = newClientFile
		},
		addPerson() {
			this.chosenPersons.push({
				person: undefined,
				id: uuid(),
				hasCustody: false
			})
		}, removePerson(id) {
			this.chosenPersons = this.chosenPersons.filter(person => person.id !== id)
		},
		setPerson(e, person, index) {
			this.chosenPersons[index]["person"] = e
		},
		setCustody(e, person, index) {
			this.chosenPersons[index]["hasCustody"] = e
		},
		async setExistentClient() {
			if (!this.$route.params.data) return (this.isMounted = true);
			this.client = this.$route.params.data;
			this.caseDataArray = [];
			const clients = await queryActiveAndInactiveClients()
			const focusedClients = clients.filter(
				(c) => c.attributes.uuid === this.client.attributes.uuid
			);

			let profilePictureUrl = undefined;
			focusedClients.forEach((c) => {
				const picture = c.attributes.profile_picture;
				if (picture) profilePictureUrl = picture._url;
			});
			let i = 0
			for (const person of this.client.attributes.persons) {
				this.chosenPersons.push({
					id: uuid(),
					person: person.toPointer(),
					hasCustody: this.client.attributes.custody[i]
				})
				i++
			}

			this.focusedClients = focusedClients;
			for (const client of focusedClients) {
				const {
					first_name,
					last_name,
					street,
					birth_date,
					entry,
					licenseStart,
					licenseEnd,
					exit,
					plz,
					administrative_district,
					location,
					gender,
					statutory_basis,
					phone1,
					phone2,
					group,
					caseDataUuid,
					goal_day,
					school, socialWorker,
					info1, info2, birth_location, inactive, all_inactive, licenseKind, licenseDays, licenseFile, licenseFee
				} = client.attributes;
				this.firstName = first_name ? first_name : "";
				this.lastName = last_name ? last_name : "";
				this.street = street ? street : "";
				this.birthday = birth_date ? birth_date : undefined;
				this.birthLocation = birth_location ? birth_location : undefined;
				this.plz = plz ? plz : "";
				this.location = location ? location : "";
				this.gender = gender ? gender : "";
				this.phone1 = phone1 ? phone1 : "";
				this.phone2 = phone2 ? phone2 : "";
				this.info1 = info1 ? info1 : "";
				this.info2 = info2 ? info2 : "";
				this.goalDay = goal_day ? goal_day : 0;
				this.dropFile = profilePictureUrl;
				this.school = school ? school.toPointer() : undefined
				this.socialWorker = socialWorker ? socialWorker.toPointer() : undefined
				this.entry = entry ? entry : undefined;
				this.exit = exit ? exit : undefined;

				const licenseStartLoc = licenseStart ? licenseStart : undefined;
				const licenseEndLoc = licenseEnd ? licenseEnd : undefined;
				const licenseDaysLoc = licenseDays ? licenseDays : [];
				const licenseFileLoc = licenseFile ? licenseFile : {};
				const groupClient = group ? group.toPointer() : undefined;
				const administrativeDistrictClient = administrative_district
					? administrative_district.toPointer()
					: "";
				const statutoryBasisClient = statutory_basis
					? statutory_basis.toPointer()
					: "";
				const inactiveClient = inactive
					? inactive
					: false;
				const all_inactiveClient = all_inactive
					? all_inactive
					: false;
				const licenseKindLoc = licenseKind ? licenseKind.toPointer() : "";
				const licenseFeeLoc = licenseFee ? String(licenseFee.toFixed(2)).replace(".", ",") : "0";
				const caseData = {
					id: caseDataUuid,
					group: groupClient,
					administrativeDistrict: administrativeDistrictClient,
					statutoryBasis: statutoryBasisClient,
					licenseStart: licenseStartLoc,
					licenseEnd: licenseEndLoc,
					inactive: inactiveClient,
					all_inactive: all_inactiveClient,
					licenseKind: licenseKindLoc,
					licenseDays: licenseDaysLoc,
					licenseFee: licenseFeeLoc,
					caseDataFileObjekt: licenseFileLoc,
					caseDataFile: {
						title: licenseFileLoc?.attributes?.title ? licenseFileLoc.attributes.title : undefined,
						category: licenseFileLoc?.attributes?.category ? licenseFileLoc.attributes.category.toPointer() : "",
						documentType: licenseFileLoc?.attributes?.documentType ? licenseFileLoc.attributes.documentType : "Posteingang",
						date: licenseFileLoc?.attributes?.date ? licenseFileLoc.attributes.date : undefined,
						creator: licenseFileLoc?.attributes?.creator ? licenseFileLoc.attributes.creator : "",
						pdf: licenseFileLoc?.attributes?.pdf ? licenseFileLoc.attributes.pdf : undefined,
					},
					isDeleted: false
				};
				this.caseDataArray.push(caseData);
			}
			this.isMounted = true;
		},
	},
};


</script>

<style lang="scss" scoped>
.image_column {
	max-width: 200px;
}

.anwesenheitstage {
	flex-direction: column;
}
</style>
