<template>
	<div v-if="isMounted">
		<!-- Bereich Klienten-Stammdaten -->
		<div v-if="client" class="panel m-5">
			<p class="panel-heading">
				Stammdaten
			</p>
			<div class="container pt-3 pb-3">
				<div class="columns is-centered">
					<div class="column image_column">
						<b-field label="Profilbild">
							<b-image :src="dropFileURL" ratio="64by64" alt="Profilbild" rounded />
						</b-field>
					</div>
				</div>
				<hr>
				<div class="columns">
					<div class="column">
						<b-field label="Vorname">
							<p class="content">
								{{ firstName }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Nachname">
							<p class="content">
								{{ lastName }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Geschlecht">
							<p class="content">
								{{ gender }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Geburtstag">
							<p class="content">
								{{ birthday ? birthday.toLocaleDateString("de-DE", {
									year: "numeric",
									month: "2-digit",
									day: "2-digit",
								}) : "" }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Geburtsort">
							<p class="content">
								{{ birthLocation ? birthLocation : "" }}
							</p>
						</b-field>
					</div>
				</div>
			</div>
		</div>

		<!-- Bereich Fallstammdaten -->
		<div class="panel m-5">
			<p class="panel-heading">
				Fallstammdaten
			</p>
			<div class="columns pt-3 pb-3">
				<div class="column">
					<b-field label="Zugang">
						<p class="content">
							{{
								client.attributes.entry ? client.attributes.entry.toLocaleDateString("de-DE", {
									year: "numeric",
									month: "2-digit",
									day: "2-digit",
								}) : ''
							}}
						</p>
					</b-field>
				</div>
				<div class="column">
					<b-field label="Abgang">
						<p class="content">
							{{
								client.attributes.exit ? client.attributes.exit.toLocaleDateString("de-DE", {
									year: "numeric",
									month: "2-digit",
									day: "2-digit",
								}) : ""
							}}
						</p>
					</b-field>
				</div>
			</div>
			<!-- Unterbereich Falldaten -->
			<p class="panel-heading">
				Falldaten
			</p>
			<div v-for="(caseData, index) in caseDataArray" :key="caseData.id" class="container pt-3 pb-3">
				<hr v-if="index > 0" style="height: 3px; background-color: black">
				<div class="columns">
					<div class="column">
						<b-field label="Gruppe">
							<p class="content">
								{{
									caseData.group.attributes.group_name
								}}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Landkreis">
							<p class="content">
								{{
									caseData.administrativeDistrict ? caseData.administrativeDistrict.attributes.name : ""
								}}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Gesetzliche Grundlage">
							<p class="content">
								{{
									caseData.statutoryBasis ? caseData.statutoryBasis.attributes.name : ""
								}}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Bewilligungsart">
							<p class="content">
								{{
									caseData.licenseKind ? caseData.licenseKind.attributes.name : ""
								}}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Entgeld (in €)">
							<p class="content">
								{{
									caseData.licenseFee
								}}
							</p>
						</b-field>
					</div>
				</div>
				<div class="columns">
					<div class="column">
						<b-field label="Bewilligung von">
							<p class="content">
								{{
									caseData.licenseStart ? caseData.licenseStart.toLocaleDateString("de-DE", {
										year: "numeric",
										month: "2-digit",
										day: "2-digit",
									}) : ""
								}}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Bewilligung bis">
							<p class="content">
								{{
									caseData.licenseEnd ? caseData.licenseEnd.toLocaleDateString("de-DE", {
										year: "numeric",
										month: "2-digit",
										day: "2-digit",
									}) : ""
								}}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Aktivität">
							<p v-if="caseData.inactive">
								Inaktiv
							</p>
							<p v-else>
								Aktiv
							</p>
						</b-field>
					</div>
				</div>
				<hr>
				<div v-if="caseData.caseDataFile" class="column anwesenheitstage"
					style="display: flex; justify-content: center; align-items: center">
					<div style="font-size: 1rem; font-weight: 700; margin-bottom: 0.375rem;">
						<p>Bewilligungsdatei</p>
					</div>
				</div>
				<div v-if="caseData.caseDataFile" class="columns">
					<div class="column">
						<b-field label="Bezeichner">
							<p class="content">
								{{ caseData.caseDataFile?.title }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Kategorie">
							<p class="content">
								{{ caseData.caseDataFile.category.attributes ?
									caseData.caseDataFile.category.attributes.name
									: "" }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Posteingang/Postausgang">
							<p class="content">
								{{ caseData.caseDataFile.documentType }}
							</p>
						</b-field>
					</div>
				</div>
				<div v-if="caseData.caseDataFile" class="columns">
					<div class="column">
						<b-field label="Datum">
							<p class="content">
								{{ caseData.caseDataFile.date ? caseData.caseDataFile.date.toLocaleDateString("de-DE", {
									year: "numeric",
									month: "2-digit",
									day: "2-digit",
								}) : "" }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Ersteller">
							<p class="content">
								{{ caseData.caseDataFile?.creator }}
							</p>
						</b-field>
					</div>
				</div>
				<div v-if="caseData.caseDataFileObjekt" class="columns">
					<div class="column">
						<div class="fileItem">
							<b-icon icon="file-pdf-box" size="is-medium" style="margin-right: 0.25rem;" />
							<a tag="a" :href="caseData?.caseDataFileObjekt?.attributes?.pdf?._url" target="_blank">
								{{ caseData?.caseDataFileObjekt?.attributes?.pdfTitle }}
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Bereich Kontakt- und Adressdaten -->
		<div class="panel m-5">
			<p class="panel-heading">
				Kontakt- und Adressdaten
			</p>
			<div class="container pt-3 pb-3">
				<div class="columns">
					<div class="column">
						<b-field label="Telefon 1">
							<p class="content">
								{{ phone1 }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Info 1">
							<p class="content">
								{{ info1 }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Telefon 2">
							<p class="content">
								{{ phone2 }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Info 2">
							<p class="content">
								{{ info2 }}
							</p>
						</b-field>
					</div>
				</div>
				<div class="columns">
					<div class="column">
						<b-field label="Straße">
							<p class="content">
								{{ street }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="PLZ">
							<p class="content">
								{{ plz }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Ort">
							<p class="content">
								{{ location }}
							</p>
						</b-field>
					</div>
				</div>
			</div>
		</div>
		<!-- Bereich Personen -->
		<div class="panel m-5">
			<p class="panel-heading">
				Personen
			</p>
			<div class="container pt-3 pb-3">
				<div v-if="socialWorker" class="columns">
					<div class="column">
						<b-field label="Sozialarbeiter">
							<p class="content">
								{{ socialWorker.attributes.first_name }} {{ socialWorker.attributes.last_name }}
							</p>
						</b-field>
					</div>
				</div>
				<div v-for="(person) in chosenPersons" :key="person.id" class="columns">
					<b-field label="Person" class="column">
						<p class="content">
							{{ person.person.attributes.first_name }} {{ person.person.attributes.last_name }}, {{
								person.person.attributes.type }}
						</p>
					</b-field>
					<b-field label="Sorgerecht" class="column">
						<p v-if="person.hasCustody" class="content">
							Ja
						</p>
						<p v-if="!person.hasCustody" class="content">
							Nein
						</p>
					</b-field>
				</div>
			</div>
		</div>

		<section class="m-2">
			<b-button class="is-danger" tag="router-link" to="/clients">
				Zurück
			</b-button>
		</section>
	</div>
</template>

<script>

/*
	<hr>
				<div v-if="caseData.licenseDays" class="columns">
					<div class="column anwesenheitstage"
						style="display: flex; justify-content: center; align-items: center">
						<div style="font-size: 1rem; font-weight: 700; margin-bottom: 0.375rem;">
							<p>Anwesenheitstage</p>
						</div>
						<div class="column anwesenheitstage"
							style="display: flex; justify-content: center; align-items: start">
							<b-checkbox style="margin-bottom: 0.25em;" v-model="caseData.licenseDays" :native-value=1
								disabled>
								Montag
							</b-checkbox>
							<b-checkbox style="margin-bottom: 0.25em;" v-model="caseData.licenseDays" :native-value=2
								disabled>
								Dienstag
							</b-checkbox>
							<b-checkbox style="margin-bottom: 0.25em;" v-model="caseData.licenseDays" :native-value=3
								disabled>
								Mittwoch
							</b-checkbox>
							<b-checkbox style="margin-bottom: 0.25em;" v-model="caseData.licenseDays" :native-value=4
								disabled>
								Donnerstag
							</b-checkbox>
							<b-checkbox style="margin-bottom: 0.25em;" v-model="caseData.licenseDays" :native-value=5
								disabled>
								Freitag
							</b-checkbox>
							<b-checkbox style="margin-bottom: 0.25em;" v-model="caseData.licenseDays" :native-value=6
								disabled>
								Samstag
							</b-checkbox>
							<b-checkbox style="margin-bottom: 0.25em;" v-model="caseData.licenseDays" :native-value=0
								disabled>
								Sonntag
							</b-checkbox>
						</div>
					</div>
				</div>
*/


/**
 * This file is for viewing a focused client
 */
import { queryActiveAndInactiveClients } from "../../lib/Query";
import { getClientPicture } from "../../lib/Picture";
import { v4 as uuid } from "uuid";

export default {
	name: "ViewClient",
	data() {
		return {
			client: undefined,
			firstName: "",
			lastName: "",
			birthday: new Date(),
			gender: "",
			isLoading: false,
			entry: new Date(),
			exit: undefined,
			phone1: "",
			phone2: "",
			plz: "",
			location: "",
			street: "",
			dropFile: undefined, //required as value before storing in vuex
			birthLocation: "",
			isMounted: false,
			socialWorker: undefined,
			chosenPersons: [],
			info2: "",
			info1: "",
			caseDataArray: [
				{
					licenseDays: [],
					licenseKind: undefined,
					id: uuid(),
					group: undefined,
					administrativeDistrict: "", //Landkreis
					statutoryBasis: "", //gesetzliche Grundlage
					licenseStart: undefined,
					licenseEnd: undefined,
					licenseFee: "0",
					inactive: false,
					caseDataFileObjekt: undefined,
					caseDataFile: {
						title: undefined,
						category: undefined,
						documentType: "Eingang",
						date: new Date(),
						creator: "",
						pdf: undefined
					}
				},
			],
		};
	},
	computed: {
		dropFileURL() {
			const pictureObject = {
				newProfilePicture: typeof this.dropFile === "string" ? undefined : this.dropFile,
				clientPicture: typeof this.dropFile === "string" ? this.dropFile : undefined,
				clientGender: this.client.attributes.gender
			}
			return getClientPicture(pictureObject)
		},
	},
	async mounted() {
		this.isMounted = true
	},
	async created() {
		if (!this.$route.params.data) return (this.isMounted = true);
		this.client = this.$route.params.data;
		this.caseDataArray = [];
		const clients = await queryActiveAndInactiveClients()
		const focusedClients = clients.filter(
			(c) => c.attributes.uuid === this.client.attributes.uuid
		);

		let profilePictureUrl = undefined;
		focusedClients.forEach((c) => {
			const picture = c.attributes.profile_picture;
			if (picture) profilePictureUrl = picture._url;
		});
		let i = 0
		for (const person of this.client.attributes.persons) {
			this.chosenPersons.push({
				id: uuid(),
				person: person.toPointer(),
				hasCustody: this.client.attributes.custody[i]
			})
			i++
		}

		this.focusedClients = focusedClients;
		for (const client of focusedClients) {
			const {
				first_name,
				last_name,
				street,
				birth_date,
				entry,
				licenseStart,
				licenseEnd,
				exit,
				plz,
				administrative_district,
				location,
				gender,
				statutory_basis,
				phone1,
				phone2,
				group,
				caseDataUuid,
				goal_day,
				socialWorker,
				info1, info2, birth_location, inactive, licenseKind, licenseDays, licenseFile, licenseFee
			} = client.attributes;
			this.firstName = first_name ? first_name : "";
			this.lastName = last_name ? last_name : "";
			this.street = street ? street : "";
			this.birthday = birth_date ? birth_date : undefined;
			this.birthLocation = birth_location ? birth_location : undefined;
			this.plz = plz ? plz : "";
			this.location = location ? location : "";
			this.gender = gender ? gender : "";
			this.phone1 = phone1 ? phone1 : "";
			this.phone2 = phone2 ? phone2 : "";
			this.info1 = info1 ? info1 : "";
			this.info2 = info2 ? info2 : "";
			this.goalDay = goal_day ? goal_day : 0;
			this.dropFile = profilePictureUrl;
			this.socialWorker = socialWorker ? socialWorker : undefined
			this.entry = entry ? entry : undefined;
			this.exit = exit ? exit : undefined;

			const licenseStartLoc = licenseStart ? licenseStart : undefined;
			const licenseEndLoc = licenseEnd ? licenseEnd : undefined;
			const licenseDaysLoc = licenseDays ? licenseDays : [];
			const licenseFileLoc = licenseFile ? licenseFile : undefined;
			const groupClient = group ? group : undefined;
			const administrativeDistrictClient = administrative_district
				? administrative_district
				: "";
			const statutoryBasisClient = statutory_basis
				? statutory_basis
				: "";
			const inactiveClient = inactive
				? inactive
				: false;
			const licenseKindLoc = licenseKind ? licenseKind : "";
			const licenseFeeLoc = licenseFee ? String(licenseFee.toFixed(2)).replace(".", ",") : "";
			const caseData = {
				id: caseDataUuid,
				group: groupClient,
				administrativeDistrict: administrativeDistrictClient,
				statutoryBasis: statutoryBasisClient,
				licenseStart: licenseStartLoc,
				licenseEnd: licenseEndLoc,
				inactive: inactiveClient,
				licenseKind: licenseKindLoc,
				licenseDays: licenseDaysLoc,
				licenseFee: licenseFeeLoc,
				caseDataFileObjekt: licenseFileLoc,
				caseDataFile: {
					title: licenseFileLoc?.attributes?.title ? licenseFileLoc.attributes.title : undefined,
					category: licenseFileLoc?.attributes?.category ? licenseFileLoc.attributes.category : "",
					documentType: licenseFileLoc?.attributes?.documentType ? licenseFileLoc.attributes.documentType : "",
					date: licenseFileLoc?.attributes?.date ? licenseFileLoc.attributes.date : "",
					creator: licenseFileLoc?.attributes?.creator ? licenseFileLoc.attributes.creator : "",
					pdf: licenseFileLoc?.attributes?.pdf ? licenseFileLoc.attributes.pdf : undefined,
				}
			};
			this.caseDataArray.push(caseData);
			this.isMounted = true;
		}
	}
}
</script>

<style lang="scss" scoped>
.image_column {
	max-width: 200px;
}

.anwesenheitstage {
	flex-direction: column;
}

.fileItem {
	flex-wrap: wrap !important;
	display: flex !important;
	align-items: center !important;
	flex-direction: row !important;
	align-content: center !important;
	justify-content: center !important;
}
</style>